
import { DynamicValueSchema } from '@vc-workspace/utils-schemas';
import { isMobile } from 'react-device-detect';

const amplitudeOptions = {
  includeUtm: true,
  includeReferrer: true,
  unsetParamsReferrerOnNewSession: true,
  includeGclid: true,
};

export function UtilsAmplitude() {

  return (
    <script
      async
      defer
      dangerouslySetInnerHTML={{
        __html: `
      (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
      ;r.type="text/javascript"
      ;r.integrity="sha384-tzcaaCH5+KXD4sGaDozev6oElQhsVfbJvdi3//c2YvbY02LrNlbpGdt3Wq4rWonS"
      ;r.crossOrigin="anonymous";r.async=true
      ;r.src="https://cdn.amplitude.com/libs/amplitude-8.5.0-min.gz.js"
      ;r.onload=function(){if(!e.amplitude.runQueuedFunctions){
      console.log("[Amplitude] Error: could not load SDK")}}
      ;var i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)
      ;function s(e,t){e.prototype[t]=function(){
      this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
      var o=function(){this._q=[];return this}
      ;var a=["add","append","clearAll","prepend","set","setOnce","unset","preInsert","postInsert","remove"]
      ;for(var c=0;c<a.length;c++){s(o,a[c])}n.Identify=o;var u=function(){this._q=[]
      ;return this}
      ;var l=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"]
      ;for(var p=0;p<l.length;p++){s(u,l[p])}n.Revenue=u
      ;var d=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","enableTracking","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"]
      ;function v(e){function t(t){e[t]=function(){
      e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}
      for(var n=0;n<d.length;n++){t(d[n])}}v(n);n.getInstance=function(e){
      e=(!e||e.length===0?"$default_instance":e).toLowerCase()
      ;if(!Object.prototype.hasOwnProperty.call(n._iq,e)){n._iq[e]={_q:[]};v(n._iq[e])
      }return n._iq[e]};e.amplitude=n})(window,document);
    `,
      }}
    />
  );
}

export function MoEngageInitialiser() {

  return null;

  if(!process.env.NEXT_PUBLIC_MOENGAGE_APP_ID) {
    return null;
  }

  return (
    <script async defer dangerouslySetInnerHTML={{
      __html: `
        !function(e,n,i,t,a,r,o,d){var s=e[a]=e[a]||[];if(s.invoked=0,s.initialised>0||s.invoked>0)return console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1;e.moengage_object=a;var l={},g=function n(i){return function(){for(var n=arguments.length,t=Array(n),a=0;a<n;a++)t[a]=arguments[a];(e.moengage_q=e.moengage_q||[]).push({f:i,a:t})}},u=["track_event","add_user_attribute","add_first_name","add_last_name","add_email","add_mobile","add_user_name","add_gender","add_birthday","destroy_session","add_unique_user_id","moe_events","call_web_push","track","location_type_attribute"],m={onsite:["getData","registerCallback"]};for(var c in u)l[u[c]]=g(u[c]);for(var v in m)for(var f in m[v])null==l[v]&&(l[v]={}),l[v][m[v][f]]=g(v+"."+m[v][f]);r=n.createElement(i),o=n.getElementsByTagName("head")[0],r.async=1,r.src=t,o.appendChild(r),e.moe=e.moe||function(){return(s.invoked=s.invoked+1,s.invoked>1)?(console.error("MoEngage Web SDK initialised multiple times. Please integrate the Web SDK only once!"),!1):(d=arguments.length<=0?void 0:arguments[0],l)},r.addEventListener("load",function(){if(d)return e[a]=e.moe(d),e[a].initialised=e[a].initialised+1||1,!0}),r.addEventListener("error",function(){return console.error("Moengage Web SDK loading failed."),!1})}(window,document,"script","https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js","Moengage");
        Moengage = moe({
          app_id: '${process.env.NEXT_PUBLIC_MOENGAGE_APP_ID}',
          debug_logs: 0,
          disable_web_push: true
        });
      `
    }} />
  )
};

export const AmplitudeEventNames = {
  PAGE_VIEWED: 'Page Viewed',
  CATEGORY_INTERACTION: 'Category Interaction',
  PRODUCT_CARD_INTERACTION: 'Product Card Interaction',
  ADDED_TO_CART: 'Added to Cart',
  ADDED_TO_WISHLIST: 'Added to Wishlist',
  PRODUCT_SHARED: 'Product Shared',
  SELECT_ADDRESS_INITIATED: 'Select Address Initiated',
  PROCEED_TO_PAYMENT: 'Proceed to Payment',
  PAYMENT_TRANSACTION_INITIATED: 'Payment Transaction Initiated',
  ADDRESS_ADDED: 'Address Added',
  ADDRESS_EDITED: 'Address Edited',
  ADDRESS_DELETED: 'Address Deleted',
  LOGIN_INITIATED: 'Login Initiated',
  SEND_OTP_CLICKED: 'Send OTP Clicked',
  RESEND_OTP_CLICKED: 'Resend OTP Clicked',
  LOGIN_SUCCESSFUL: 'Login Successful',
  ERROR_SCREEN: 'Error Screen',
  ERROR_SCREEN_BACK_TO_HOME: 'Error Screen Back to Home',
  FOOTER_INTERACTION: 'Footer Interaction',
  DELETED_FROM_CART: 'Deleted from Cart',
  GO_TO_CART: 'Go to Cart',
  SEARCH_SUGGESTION_INTERACTED: 'Search Suggestion Interacted',
  PAYMENT_METHOD_SELECTED: 'Payment Method Selected',
  VIEW_OFFER_DETAILS: 'View Offer Details',
  VIEW_DELIVERY_CHARGE_DETAILS: 'View Delivery Charge Details',
  VIEW_ALL_AVAILABLE_OFFERS: 'View All Available Offers',
  APPLY_OFFER: 'Apply Offer',
  CHECK_OFFER_VALIDITY: 'Check Offer Validity',
  VIEW_FULL_CATALOGUE: 'View Full Catalogue',
  SUBSCRIBE_SHOP: 'Subscribe Shop',
  CONTACT_SELLER: 'Contact Seller',
  VIEW_ALL_CLICKED: 'View All Clicked',
  NEXT_CLICKED: 'Next Clicked',
  PREV_CLICKED: 'Prev Clicked',
  APP_LINK_REQUESTED: 'App Link Requested',
  B2B_ENQUIRY_FORM_INITIATED: 'B2B Enquiry Form Initiated',
  B2B_ENQUIRY_SENT: 'B2B Enquiry Sent',
  GO_TO_WISHLIST: 'Go to Wishlist',
  GO_TO_ACCOUNT: 'Go to Account',
  GO_TO_EXPLORE: 'Go to Explore',
  DOWNLOAD_LINK_CLICKED: 'Download Link Clicked',
  SEARCH_CLICKED: 'Search Clicked',
  USE_WALLET_CLICKED: 'Use Wallet Clicked',
  WALLET_BALANCE_USED: 'Wallet Balance Used',
  REFERRAL_PROMPT_OPENED: 'Referral Prompt Opened',
  PRODUCT_REVIEW_INITIATED: 'Product Review Initiated',
  PRODUCT_RATING_SUBMITTED: 'Product Rating Submitted',
  PRODUCT_REVIEW_SUBMITTED: 'Product Review Submitted',
  PRODUCT_REVIEW_EDITED: 'Product Review Edited',
  REVIEW_VIEWED: 'Review Viewed',
  DELETED_FROM_WISHLIST: 'Deleted from Wishlist',
};

export const AmplitudeUserProperties = {
  DEVICE_TYPE: 'deviceType',
};

export type AmplitudeProperties = {
  referrer?: string;
  page_type?: string;
  page_url?: string;
  page_name?: string;
  page_identifier?: string;
  parent_l1_category?: string;
  parent_l0_category?: string;
  category_filter?: string;
  price_filter?: string;
  discount_filter?: string;
  sort_filter?: string;
  page_num?: number;
  source?: string;
  category_level?: number;
  name?: string;
  source_name?: string;
  source_identifier?: string;
  source_l0_category?: string;
  source_l1_category?: string;
  source_l2_category?: string;
  identifier?: string;
  shop_name?: string;
  product_price?: number;
  product_variation?: string;
  shop_id?: string;
  shop_slug?: string;
  l0_category?: string;
  l1_category?: string;
  l2_category?: string;
  view_more_l2_category?: string;
  city?: string;
  pincode?: string;
  order_amount?: number;
  total_order_amount?: number;
  delivery_charges?: number;
  payment_method?: string;
  is_retry?: boolean;
  order_status?: string;
  error_code?: string;
  social_icon?: string;
  order_id?: string;
  quantity?: number;
  search_keyword?: string;
  suggestion_type?: string;
  position?: number;
  count?: number;
  order_type?: string;
  is_shop_platform?: boolean;
  offer_code?: string;
  is_b2b_platform?: boolean;
  type?: string;
  itemUsId?: string;
  item_us_id?: string;
  product_name?: string;
  used_wallet_amount?: number;
  referral_code_included?: string;
  remaining_referrals?: number;
  has_social_proof?: boolean;
  social_proof_tags?: string;
  rating?: number;
  has_uploaded_photos?: boolean;
  has_written_reviews?: boolean;
  has_product_review?: boolean;
  has_shop_review?: boolean;
  review_type?: string;
  is_edit_review?: boolean;
  is_mobile?: boolean;
};

declare global {
  interface Window {
    amplitude: any;
    Moengage: any;
  }
}

function checkAndInitialiseOldAmplitude() {
  if (!window?.amplitude?.getInstance('old_project')?.options?.deviceId) {
    window.amplitude.getInstance('old_project').init(process.env.NEXT_PUBLIC_AMPLITUDE_OLD_KEY, null, {
      includeUtm: true,
      includeReferrer: true,
      unsetParamsReferrerOnNewSession: true,
      includeGclid: true,
    });
  }
}

function checkAndInitialiseNewAmplitude() {
  if (!window?.amplitude?.getInstance('new_project')?.options?.deviceId) {
    window.amplitude.getInstance('new_project').init(process.env.NEXT_PUBLIC_AMPLITUDE_NEW_PROJECT, null, {
      includeUtm: true,
      includeReferrer: true,
      unsetParamsReferrerOnNewSession: true,
      includeGclid: true,
    });
  }
}

function sendAmplitudeDataToOldProject(eventName: string, amplitudeProperties: AmplitudeProperties) {
  window.amplitude.getInstance('old_project').logEvent(eventName, amplitudeProperties);
}

function sendAmplitudeDataToNewProject(eventName: string, amplitudeProperties: DynamicValueSchema) {
  window.amplitude.getInstance('new_project').logEvent(eventName, amplitudeProperties);
}

export function sendAmplitudeData(eventName: string, amplitudeProperties: AmplitudeProperties) {
  
  if (typeof window !== 'undefined' && window.amplitude && process.env.NEXT_PUBLIC_AMPLITUDE_OLD_KEY) {
    
    checkAndInitialiseOldAmplitude();
    checkAndInitialiseNewAmplitude();

    amplitudeProperties.is_mobile = isMobile;
    amplitudeProperties.page_url = window.location.href;
    amplitudeProperties.referrer = document.referrer;
    sendAmplitudeDataToOldProject(eventName, amplitudeProperties);
    sendAmplitudeDataToNewProject(eventName, amplitudeProperties);

    if(window.Moengage) {
      window.Moengage.track_event(eventName, amplitudeProperties);
    }
  } else {
    console.log(eventName, amplitudeProperties);
  }
}

export function setAmplitudeUserId(userId: string) {
  if (typeof window !== 'undefined' && window.amplitude && process.env.NEXT_PUBLIC_AMPLITUDE_OLD_KEY) {
    checkAndInitialiseOldAmplitude();
    checkAndInitialiseNewAmplitude();

    window.amplitude.setUserId(userId);

    if(window.Moengage) {
      window.Moengage.add_unique_user_id(userId);
    }
  }
}

export function setUserProperty(key: string, value: string) {
  if (typeof window !== 'undefined' && window.amplitude && process.env.NEXT_PUBLIC_AMPLITUDE_OLD_KEY) {
    checkAndInitialiseOldAmplitude();
    checkAndInitialiseNewAmplitude();

    const identify = new window.amplitude.Identify().set(key, value);
    window.amplitude.identify(identify);

    if(window.Moengage) {
      window.Moengage.add_user_attribute(key, value);
    }
  }
}
