import { useEffect } from "react";

/* eslint-disable-next-line */
export interface UtilsFbPixelProps {
  fbPixelIds?: string[],
  fbPixelId?: string
}

export function UtilsFbPixelScript(props: UtilsFbPixelProps) {
  if(!props.fbPixelId) {
    return null;
  }

  return (
    <>
      <script
        async
        defer
        dangerouslySetInnerHTML={{
          __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', ${props.fbPixelId});
        fbq('track', 'PageView');
      `,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          alt="pixel"
          style={{ display: 'none' }}
          src={`https://www.facebook.com/tr?id=${props.fbPixelId}&ev=PageView&noscript=1`}
        />
      </noscript>
    </>
  );
}

export function UtilsFbPixelInitialise(props: UtilsFbPixelProps) {

  return null;
}

declare global {
  interface Window { fbq: any }
}

export const FBPixelEvents = {
  'VIEW_CONTENT_STANDARD_EVENT': 'ViewContent',
  'ADD_TO_CART_STANDARD_EVENT': 'AddToCart',
  'COMPLETE_REGISTRATION_STANDARD_EVENT': 'CompleteRegistration',
  'LEAD_STANDARD_EVENT': 'Lead',
  'ADD_TO_WISHLIST_STANDARD_EVENT': 'AddToWishlist',
  'ORDER_SAMPLE_ADDED': 'OrderSampleAdded'
};

export type FBPixelProperties = {
  value?: number,
  currency?: string,
  content_type?: string,
  content_ids?: string[],
  content_name?: string
}

export function trackFbPixel(eventName: string, eventProperties?: FBPixelProperties) {

  if(typeof window !== 'undefined' && window.fbq) {
    window.fbq('track', eventName, eventProperties);
  }
}


