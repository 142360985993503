export const FooterConstants = {
  support_number: '+91 8197935891',
  support_email: 'support@vibecity.club',
  address: 'HSR Layout, Bengaluru, Karnataka 560102',
  about_us_url: '/about',
  sell_on_vibecity_url: '/maker',
  privacy_policy_url: '/privacy-policy',
  terms_conditions_url: '/terms-conditions',
  return_policy_url: '/return-shipping-policy',
  instagram_url: 'https://www.instagram.com/vibecity.bazaar/',
  youtube_url: '',
  facebook_url: 'https://www.facebook.com/VibecityBazaar/',
  twitter_url: 'https://twitter.com/clubvibecity',
};

export const FilterTypes = {
  Category: 'CATEGORY',
  Price: 'PRICE',
  Discount: 'DISCOUNT',
};
export const CategoryFilterKey = 'filterByCategory';
export const SortByKey = 'sortBy';
export const PriceFilterKey = 'filterByPrice';
export const DiscountFilterKey = 'filterByDiscount';
export const SourceKey = 'source';
export const SourceNameKey = 'source_name';

export const ImageTypes = {
  HomePageBanner: 'HOME_PAGE_BANNER',
  Banner: 'BANNER',
  Card: 'CARD',
};

export const ImageAccessibility = {
  Mobile: 'MOBILE',
  Desktop: 'DESKTOP',
};

export const VibecityAccessibility = {
  Desktop: 'DESKTOP',
  Mobile: 'MOBILE',
};

export const MARKETPLACE_ACCESS_TOKEN = 'vibe_access_token';
export const MARKETPLACE_USER_ID = 'userId';
export const MARKETPLACE_CART_KEY = 'my_vc_cart_v2';
export const SHOP_CART_PREFIX = 'shop_cart_';

export const PaymentMethods = {
  Others: 'others',
  UPI: 'upi',
  COD: 'cod',
  WALLET: 'vc_wallet'
};

export const UPIPaymentMethods = {
  GPay: 'gpay',
  Paytm: 'paytm',
  Phonepe: 'phonepe',
  BhimUPI: 'bhimupi',
};

export const SEARCH_QUERY_KEY = 'q';

export const PageTypes = {
  HOMEPAGE: 'HOMEPAGE',
  L2_CATEGORY_PAGE: 'L2 CATEGORY PAGE',
  L1_CATEGORY_PAGE: 'L1 CATEGORY PAGE',
  MARKETPLACE_SHOP_PAGE: 'MARKETPLACE SHOP PAGE',
  MARKETPLACE_SHOP_CATALOGUE_PAGE: 'MARKETPLACE_SHOP_CATALOGUE_PAGE',
  MARKETPLACE_SHOP_OFFER_PAGE: 'MARKETPLACE SHOP OFFER PAGE',
  MARKETPLACE_COLLECTION_PAGE: 'MARKETPLACE COLLECTION PAGE',
  PRODUCT_PAGE: 'PRODUCT PAGE',
  SEARCH_PAGE: 'SEARCH PAGE',
  SHOP_CATALOGUE_PAGE: 'SHOP CATALOGUE PAGE',
  SHOP_PRODUCT_PAGE: 'SHOP PRODUCT PAGE',
  CHECKOUT: 'CHECKOUT',
  SELLER_SHOP_PAGE: 'SELLER SHOP PAGE',
  MAKER: 'MAKER',
  CATEGORY_LANDING_PAGE: 'CATEGORY LANDING PAGE',
  PRODUCT_REVIEW_PAGE: 'PRODUCT REVIEW PAGE',
  SHOP_REVIEW_PAGE: 'SHOP REVIEW PAGE',
  WISHLIST_PAGE: 'WISHLIST PAGE',
};

export const CategorySelectionSources = {
  NAVBAR: 'navbar',
  SIDEBAR: 'sidebar',
  EXPLORE: 'explore',
};

export const AddToWishlistSource = {
  PRODUCT_PAGE: 'product_page',
};

export const LoginTypes = {
  OTP: 'otp',
};

export const SearchSuggestionTypes = {
  SHOP: 'SHOP',
  KEYWORD: 'KEYWORD',
};

export const OrderTypes = {
  MARKETPLACE: 'MARKETPLACE',
  SHOP: 'SHOP',
};

export const SESSION_START_KEY = 'Session Started Timestamp';

export const HomePageSectionTypes = {
  HOMEPAGE_HERO_BANNER: 'HOMEPAGE_HERO_BANNER',
  SIA_COLLECTION: 'SIA_COLLECTION',
  SIA_COLLECTION_GROUP: 'SIA_COLLECTION_GROUP',
  SHOP_GROUP: 'SHOP_GROUP',
  CATEGORY_SIA_COLLECTION_GROUP: 'CATEGORY_SIA_COLLECTION_GROUP',
  BANNER_CAROUSEL: 'BANNER_CAROUSEL',
};

export const LaymanHomePageSectionTypes = {
  HERO_CHIPS: 'Homepage Hero Chips',
  SIA_COLLECTION: 'Homepage Collection Products',
  SIA_COLLECTION_GROUP: 'Homepage Collection List',
  SHOP_GROUP: 'Homepage Seller Section',
  CATEGORY_SIA_COLLECTION_GROUP: 'Homepage Category Collection Bubble',
  BANNER_CAROUSEL: 'Homepage Banner Section',
};

export const HomepageSessionCookieKey = 'SessionID';
export const VibeAccessTokenKey = 'vibe_access_token';

export const INR_TO_DOLLAR_RATE = 77;

export const DOLLAR_CURRENCY_ID = '5879fb9a14f89e036eb595c0';

export const ComputedMPListingStatus = {
  ACTIVE: 'ACTIVE',
  RESTRICTED: 'RESTRICTED',
  REMOVED: 'REMOVED',
};

export const OfferVersion = {
  V1: 'V1',
  V2: 'V2',
};

export const SHARED_COUPON_CODE = 'Shared Coupon Code';

export const BannerWidgetDisplayType = {
  DEFAULT: 'DEFAULT',
  FULL_WIDTH: 'FULL_WIDTH',
};

export const TTL_IN_MS = {
  TWO_DAYS: 172800000,
  FOUR_DAYS: 345600000,
  ONE_WEEK: 604800000,
  TWO_WEEKS: 1209600000,
  ONE_MIN: 60000,
  TWO_MINS: 120000,
};
export const LOCAL_WISHLIST_KEY = 'My Local Wishlist';
export const CATEGORY_STORAGE_KEY = 'All Categories V2';
export const CURATED_CATEGORY_STORAGE = 'Curated Categories V2';

export const COMMISSION_LOW = 0.03;
export const COMMISSION_HIGH = 0.15;
export const VC_SERVICE_CHARGE = 0.03;
export const VC_REFERRAL_CHARGE = 0.12;

export const USER_VISIT_METADATA_KEY = 'vc_mp_user_visit_metadata';
export const USER_INTERNAL_NAVIGATION_KEY = 'vc_mp_client_internal_navigation';
export const VC_REFERRERS = ['vibecity.com', 'staging.vibecity.club', 'google.co.in', 'google.com', 'ads.google.com', 'localhost'];

export const INTERNAL_NAVIGATION_UTM_MEDIUM = 'mp_routing';
export const VC_SHARING_UTM_SOURCE = 'marketplace_sharing';
export const UtmParams = {
  UTM_MEDIUM: 'utm_medium',
  UTM_SOURCE: 'utm_source',
  UTM_CAMPAIGN: 'utm_campaign',
};
export const VC_APP_DOWNLOAD_BANNER_KEY = 'vc-marketplace-app-download-banner';
export const VC_APP_PLAY_STORE_LINK = 'https://play.google.com/store/apps/details?id=com.vibecity.consumerapp';
export const VC_APP_STORE_LINK = 'https://apps.apple.com/us/app/vibecity-online-handmade-bazar/id1658694731';
export const VC_APP_GENERIC_DOWNLOAD_LINK = 'https://vibecity.app.link/downloadApp';
export const VC_APP_REFER_AND_EARN_KEY = 'vc_app_refer_and_earn_key';
export const VC_APP_GUEST_REFER_AND_EARN_KEY = 'vc_app_refer_and_earn_guest_key';

export enum OrderShippedStatus {
  B2B_PLACED = "B2B_PLACED",
  B2B_SHIPPED = "B2B_SHIPPED",
  B2B_DELIVERED = "B2B_DELIVERED"
}

export const OfferNonApplicabilityReasonCodes = {
  'INSUFFICIENT_CART_AMOUNT': 'INSUFFICIENT_CART_AMOUNT'
}

export enum SIASocialTagLevel {
  HIGH = "HIGH",
  LOW = "LOW"
}

export enum ReviewTemplateTypes {
  SELLER = 'MAKER_FEEDBACK',
  PRODUCT = 'PRODUCT_FEEDBACK'
}

export enum ReviewTemplateEntryTypes {
  STAR_RATING = 'STAR_RATING',
  FREE_TEXT = 'FREE_TEXT',
  IMAGE_UPLOAD = 'IMAGE_UPLOAD'
}

export enum SignedUrlFileCategory {
  VC_PRODUCT_FEEDBACK = "VC_PRODUCT_FEEDBACK"
}

export enum ReviewEntityModel {
  SHOP = 'Shop',
  PRODUCT = 'Sia'
}

export const DisplayReviewTypes = {
  PRODUCT: "PRODUCT",
  SHOP: "SHOP"
}

export const PRODUCT_NAME_CHAR_LIMIT = 110;

// Add to Cart Values
export enum ATCValues {
  ALLOWED = "ALLOWED",
  NOT_ALLOWED = "NOT_ALLOWED"
}

export enum RetailRecommendationTypes {
  PRODUCT_PAGE_SIMILAR_ITEMS = "pd_similar",
  PRODUCT_PAGE_OYML = "pd_oyml",
  PRODUCT_PAGE_SHOP_SIMILAR_ITEMS = "pd_shop_similar"
}