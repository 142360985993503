import axios from 'axios';
import {
  Image,
  DisplayImage,
  SIAMeta,
  Price,
  RouteContext,
  ErrorResponse,
  PageOffer,
  DynamicKeySchema,
  UTMQuery,
  Shop,
  ShopSocialMediaProfile,
  SocialMediaProfiles,
  IPQ,
  Offer,
  PaymentEntriesType,
  SIASocialTagSchema,
  DynamicValueSchema,
} from '@vc-workspace/utils-schemas';
import { ParsedUrlQuery } from 'querystring';
import { PaymentMethods, SIASocialTagLevel, USER_INTERNAL_NAVIGATION_KEY, VC_APP_DOWNLOAD_BANNER_KEY } from '@vc-workspace/utils-constants';

export function getCardImage(imageObj: Image): DisplayImage {
  if (!imageObj) {
    return {
      url: '',
      alt: '',
      webP: '',
    };
  }

  if (imageObj.xUrls && imageObj.xUrls.length) {
    const cardImage = imageObj.xUrls.filter((item) => item.xFactor === '2')[0];

    return {
      url: cardImage.url,
      alt: '',
      webP: cardImage.webPUrl,
    };
  }

  return {
    url: imageObj.url,
    alt: '',
    webP: '',
  };
}

export function getDisplayImageObj(imageObj: Image, targetXUrl: string): DisplayImage {
  if (!imageObj) {
    return {
      url: '',
      alt: '',
      webP: '',
    };
  }

  if (imageObj.xUrls && imageObj.xUrls.length) {
    const placeholderImage = imageObj.xUrls.filter((item) => item.xFactor === '0')[0];
    const cardImage = imageObj.xUrls.filter((item) => item.xFactor === targetXUrl)[0];

    return {
      url: cardImage.url,
      alt: '',
      webP: cardImage.webPUrl,
    };
  }

  return {
    url: imageObj.url,
    alt: '',
    webP: '',
  };
}

export function getSIABestPrice(siaMeta: SIAMeta): Price {
  return siaMeta.bestPrice.filter((item) => item.orderType === 'MARKETPLACE')[0];
}

export function getMarketplacePrice(prices: Price[]): Price {
  return prices.filter((item) => item.orderType === 'MARKETPLACE')[0];
}

export function getShopPrice(prices: Price[]): Price {
  return prices.filter((item) => item.orderType === 'SHOP')[0];
}

export function getPartnerPrice(prices: Price[]): Price {
  return prices.filter((item) => item.orderType === 'PARTNER')[0];
}

export function getB2BPrice(prices: Price[]): Price[] {
  return prices.filter((item) => item.orderType === 'B2B');
}

export function getB2BVariationPrice(ipqs: IPQ[], variationUuid?: string): Price {
  if(!variationUuid) {
    return ipqs[0].prices.filter((item) => item.orderType === 'B2B')[0];
  }

  const targetIpq = ipqs.filter(ipq => ipq.variationUuid === variationUuid)[0];

  return targetIpq.prices.filter((item) => item.orderType === 'B2B')[0];
}

export function getFilledRange(start: number, end: number): number[] {
  return Array(end - start + 1)
    .fill(0)
    .map((item, index) => start + index);
}

export function getRouteContextData(context: any, paramsKey?: string): RouteContext {
  const query = context.query;

  // Delete route params key from query
  if (paramsKey) {
    delete query[paramsKey];
  }

  let params = [];

  if (paramsKey) {
    const contextParams = context.params ? context.params[paramsKey] : context.query[paramsKey];

    if (Array.isArray(contextParams)) {
      params = contextParams;
    } else {
      params = [contextParams];
    }
  }

  return {
    pathname: context.resolvedUrl ? context.resolvedUrl?.split('?')[0] : context.asPath.split('?')[0],
    query: context.query,
    params,
    isMobileView: checkIfMobileView(context),
    isAndroid: checkIfAndroid(context),
    isIOS: checkIfIOS(context),
  };
}

export function getErrorResponse(err: any): ErrorResponse {
  return {
    statusCode: err.response?.status || 500,
    error: err.response?.data?.error || 'Something went wrong, please try after some time',
    message: err.response?.data?.message || 'Something went wrong, please try after some time',
  };
}

export function handleApiError(err: any, context: RouteContext): any {
  if (axios.isAxiosError(err)) {
    const errorResponse: ErrorResponse = getErrorResponse(err);

    if (errorResponse.statusCode === 404) {
      return {
        notFound: true,
      };
    } else {
      return {
        props: {
          errorStatusCode: 500,
          err,
          context,
        },
      };
    }
  } else {
    return {
      props: {
        errorStatusCode: 500,
        err,
        context,
      },
    };
  }
}

export function checkIfMobileView(context: any): boolean {
  const isMobileView = (context.req ? context.req.headers['user-agent'] : navigator.userAgent).match(
    /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
  );

  //Returning the isMobileView as a prop to the component for further use.
  return Boolean(isMobileView);
}

export function checkIfAndroid(context: any): boolean {
  const isAndroid = (context.req ? context.req.headers['user-agent'] : navigator.userAgent).match(/Android/i);

  //Returning the isMobileView as a prop to the component for further use.
  return Boolean(isAndroid);
}

export function checkIfIOS(context: any): boolean {
  const isIOS = (context.req ? context.req.headers['user-agent'] : navigator.userAgent).match(/iPhone|iPad|iPod/i);

  //Returning the isMobileView as a prop to the component for further use.
  return Boolean(isIOS);
}

// If needsMobile is false the function will assume image is requested for desktop
export function getImagesByType(images: Image[], type: string, targetXUrl: string, accessibility?: string, skipFallbackImage?: boolean): DisplayImage {
  if (!images || !images.length) {
    return {
      url: '',
      alt: '',
      webP: '',
    };
  }

  const allTypeImages = images.filter((item) => item.typeInfo && item.typeInfo.indexOf(type) !== -1);

  if (accessibility) {
    const accessibilityImages = allTypeImages.filter((item) => item.accessibility?.indexOf(accessibility) !== -1);

    if (accessibilityImages.length) {
      return getDisplayImageObj(accessibilityImages[0], targetXUrl);
    }
  }

  if (allTypeImages.length) {
    return getDisplayImageObj(allTypeImages[0], targetXUrl);
  }

  if (skipFallbackImage) {
    return {
      url: '',
      alt: '',
      webP: '',
    };
  } else {
    return getDisplayImageObj(images[0], targetXUrl);
  }
}

export function getOfferText(pageOffer: PageOffer): string {
  if (!pageOffer) {
    return '';
  }

  switch (pageOffer.offerType) {
    case 'UPTO_X_PERCENT_OFF':
      return `Upto ${pageOffer.offerValue}% OFF`;
    case 'FLAT_X_PERCENT_OFF':
      return `Flat ${pageOffer.offerValue}% OFF`;
    case 'FLAT_INR_X_OFF':
      return `FLAT ₹${pageOffer.offerValue / 100} OFF`;
    case 'MIN_X_PERCENT_OFF':
      return `Min ${pageOffer.offerValue}% OFF`;
    default:
      return '';
  }
}

export function getKeyFromStorage(key: string): any {
  if (typeof document === 'undefined') {
    return '';
  }

  if (typeof Storage !== 'undefined') {
    const storageValueJSONString = localStorage.getItem(key);
    let storageValue = { value: '', expiry: '' };

    if (!storageValueJSONString) {
      return '';
    }

    try {
      storageValue = JSON.parse(storageValueJSONString);
    } catch (e) {
      storageValue = {
        value: storageValueJSONString,
        expiry: new Date().toISOString(),
      };
    }

    const now = new Date();

    if (now >= new Date(storageValue.expiry)) {
      return '';
    }

    return storageValue.value;
  }

  /** ********************************************
    Use Cookies if local storage is not present
  ********************************************* */

  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = document.cookie.split(';');

  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i += 1) {
    const cookiePair = cookieArr[i].split('=');

    /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
    if (key.toString() === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return '';
}

const ONE_DAY = 86400000;
const ONE_MIN = 60000;

/**************************************************
    expiry_timeframe can only be days or minutes
    default is in days
***************************************************/
export function setKeyInStorage(key: string, value: string, expiry_timeframe = 30, setExpiryInMinutes?: boolean) {
  if (typeof document === 'undefined' || !key || !value) {
    return;
  }

  let expiryOffset = ONE_DAY * expiry_timeframe;

  if (setExpiryInMinutes) {
    expiryOffset = ONE_MIN * expiry_timeframe;
  }

  let expiry;

  if (typeof Storage !== 'undefined') {
    expiry = new Date(Date.now() + expiryOffset);
    expiry = expiry.toISOString();

    localStorage.setItem(
      key,
      JSON.stringify({
        value,
        expiry,
      })
    );
  }

  if (expiry_timeframe) {
    expiry = `; Max-Age=${expiry_timeframe * (expiryOffset / 1000)}`; // Convert to seconds for cookies
  } else {
    expiry = '';
  }

  document.cookie = `${key}=${value}${expiry};`;

  document.cookie = `${key}=${value}${expiry}; Path=/`;
}

export function removeKeyFromStorage(key: string) {
  if (typeof document === 'undefined') {
    return;
  }

  if (typeof Storage !== 'undefined') {
    localStorage.removeItem(key);
  }

  document.cookie = `${key}= ; Max-Age=-999999; Path=/`;
}

export function getKeyFromSessionStorage(key: string): string {
  if (typeof document === 'undefined') {
    return '';
  }

  if (typeof Storage !== 'undefined') {
    const storageValueJSONString = sessionStorage.getItem(key);
    let storageValue = { value: '', expiry: '' };

    if (!storageValueJSONString) {
      return '';
    }

    try {
      storageValue = JSON.parse(storageValueJSONString);
    } catch (e) {
      storageValue = {
        value: storageValueJSONString,
        expiry: new Date().toISOString(),
      };
    }

    const now = new Date();

    if (now >= new Date(storageValue.expiry)) {
      return '';
    }

    return storageValue.value;
  }

  /** ********************************************
    Use Cookies if local storage is not present
  ********************************************* */

  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = document.cookie.split(';');

  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i += 1) {
    const cookiePair = cookieArr[i].split('=');

    /* Removing whitespace at the beginning of the cookie name
      and compare it with the given string */
    if (key.toString() === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return '';
}

/**************************************************
    expiry_timeframe can only be days or minutes
    default is in days
***************************************************/
export function setKeyInSessionStorage(key: string, value: string, expiry_timeframe = 30, setExpiryInMinutes?: boolean) {
  if (typeof document === 'undefined' || !key || !value) {
    return;
  }

  let expiryOffset = ONE_DAY * expiry_timeframe;

  if (setExpiryInMinutes) {
    expiryOffset = ONE_MIN * expiry_timeframe;
  }

  let expiry;

  if (typeof Storage !== 'undefined') {
    expiry = new Date(Date.now() + expiryOffset);
    expiry = expiry.toISOString();

    sessionStorage.setItem(
      key,
      JSON.stringify({
        value,
        expiry,
      })
    );
  }

  if (expiry_timeframe) {
    expiry = `; Max-Age=${expiry_timeframe * (expiryOffset / 1000)}`; // Convert to seconds for cookies
  } else {
    expiry = '';
  }

  document.cookie = `${key}=${value}${expiry};`;

  document.cookie = `${key}=${value}${expiry}; Path=/`;
}

export function removeKeyFromSessionStorage(key: string) {
  if (typeof document === 'undefined') {
    return;
  }

  if (typeof Storage !== 'undefined') {
    sessionStorage.removeItem(key);
  }

  document.cookie = `${key}= ; Max-Age=-999999; Path=/`;
}

export function queryHasUTMParams(query: DynamicKeySchema): boolean {
  if (!query) {
    return false;
  }

  if (query['utm_source'] || query['utm_medium'] || query['utm_campaign']) {
    return true;
  }

  return false;
}

export function getUTMFromQuery(query: ParsedUrlQuery): UTMQuery {
  const utmQuery: UTMQuery = {};
  if (!query) {
    return utmQuery;
  }

  if (query['utm_source']) {
    utmQuery['utm_source'] = query['utm_source'];
  }

  if (query['utm_medium']) {
    utmQuery['utm_medium'] = query['utm_medium'];
  }

  if (query['utm_campaign']) {
    utmQuery['utm_campaign'] = query['utm_campaign'];
  }

  return utmQuery;
}

export function getUTMFromAttributionJSON(jsonPayload: DynamicValueSchema): DynamicKeySchema {
  const params: DynamicKeySchema = {};

  if(jsonPayload.us ) {
    params.utm_source = JSON.stringify(jsonPayload.us);
  }
  if(jsonPayload.um) {
    params.utm_medium = JSON.stringify(jsonPayload.um);
  }
  if(jsonPayload.uc) {
    params.utm_campaign = JSON.stringify(jsonPayload.uc);
  }

  return params;
}

export function getDoubleDigit(num: number): string {
  return num.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
}

export function getQueryValueAsString(query: DynamicKeySchema, queryKey: string): string {
  if (!query[queryKey]) {
    return '';
  }

  let returnValueArr: string[] = [];

  if (typeof query[queryKey] === 'string') {
    returnValueArr = [query[queryKey]];
  } else {
    returnValueArr = [...query[queryKey]];
  }

  return returnValueArr.join(', ');
}

export function getPaymentCallbackUrl(amount: number, orderUsId?: string, shop?: Shop, isCOD?: boolean): string {
  let url = window.location.origin + `/payment/marketplace?total_amount=${amount / 100}&currency_symbol=₹`;

  if (orderUsId) {
    url += `&order_us_id=${orderUsId}`;
  }

  if (shop) {
    url += `&shop_name=${shop.name}&shop_slug=${shop.slug}`;
  }

  if (isCOD) {
    url += `&is_cod=${isCOD}`;
  }

  return url;
}

export function getSocialMediaProfiles(shopMediaProfiles: ShopSocialMediaProfile[]): SocialMediaProfiles {
  const profiles: SocialMediaProfiles = {};

  shopMediaProfiles.forEach((profile) => {
    switch (profile.socialMediaType) {
      case 'FACEBOOK':
        profiles.facebook = profile.handle;
        break;
      case 'INSTAGRAM':
        profiles.instagram = profile.handle;
        break;
      case 'TWITTER':
        profiles.twitter = profile.handle;
        break;
      case 'YOUTUBE':
        profiles.youtube = profile.handle;
        break;
    }
  });

  return profiles;
}

export function getBestOffer(allOffers: Offer[], selectedIPQ?: string): Offer {
  let bestOffer = allOffers[0];
  let maxOfferDiscount = 0;

  allOffers.forEach((offer) => {
    let offerBenefit = offer.appliedV2BenefitsProduct && offer.appliedV2BenefitsProduct[0];

    if (selectedIPQ) {
      offerBenefit = offer.appliedV2BenefitsProduct && offer.appliedV2BenefitsProduct.filter((benefit) => benefit.ipqId === selectedIPQ)[0];
    }

    if (offerBenefit && offerBenefit.totalDiscountValue > maxOfferDiscount) {
      maxOfferDiscount = offerBenefit.totalDiscountValue;
      bestOffer = offer;
    }
  });

  return bestOffer;
}

export function isNumeric(num: string): boolean {
  return /^[0-9]+$/.test(num) || num === '';
}

export function getStrcuturedSlugFromShopName(name: string): string {
  return name
    .replace(/[^a-zA-Z\d]/g, '-')
    .replace(/^-+/, '')
    .replace(/-+$/, '')
    .replace(/-+/g, '-')
    .trim()
    .toLowerCase();
}

export function getStrcuturedSlug(name: string): string {
  return name
    .replace(/[^a-zA-Z\d]/g, '-')
    .replace(/^-+/, '')
    .replace(/-{2,}$/, '')
    .replace(/-+/g, '-')
    .trim()
    .toLowerCase();
}

export function getDayName(date: Date): string {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thurs', 'Fri', 'Sat'];
  return days[new Date(date).getDay()];
}

//* Local storage helper :
export const LocalStorage = {
  isSupported() {
    return typeof Storage !== 'undefined';
  },
  hasKey(key: string): boolean {
    if (this.isSupported()) {
      return Object.prototype.hasOwnProperty.call(localStorage, key);
    }
    return false;
  },
  getKey(key: string, removeExpiry = true) {
    if (this.hasKey(key)) {
      const data = localStorage.getItem(key);
      if (data) {
        try {
          const validData = JSON.parse(data).map((dataFromMap: Record<string, string | string[] | number>) => {
            if (!this.hasExpired(dataFromMap)) {
              const updatedDataFromMap = { ...dataFromMap };
              if (removeExpiry && updatedDataFromMap.expiry) {
                delete updatedDataFromMap.expiry;
              }
              return updatedDataFromMap;
            } else {
              return null;
            }
          });
          return validData;
        } catch (e) {
          console.error(e);
        }
      }
    }
    return null;
  },
  setKey(key: string, value: Record<string, string | string[] | number>[]) {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  },
  appendToExistingKey(key: string, arrItem: Record<string, string | string[] | number>) {
    if (this.hasKey(key)) {
      const currData = sanitiseArray(this.getKey(key, false));
      currData.push(arrItem);
      this.setKey(key, currData);
    } else {
      this.setKey(key, [arrItem]);
    }
  },
  forceWriteKey(key: string, value: Record<string, string | string[] | number>) {
    localStorage.removeItem(key);
    localStorage.setItem(key, JSON.stringify(value));
  },
  hasExpired(item: Record<string, string | string[] | number>): boolean {
    const now = new Date();
    if (!item) return true;
    if (now.getTime() > item.expiry) {
      return true;
    }
    return false;
  },
  getLatestCreatedAt(key: string) {
    if (this.hasKey(key)) {
      try {
        const data = this.getKey(key);
        if (data) {
          return data[data.length - 1].cat;
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      return null;
    }
  },
};
export const VCInternalNavigation = {
  setInternalNavigation(value: boolean) {
    try {
      sessionStorage.setItem(USER_INTERNAL_NAVIGATION_KEY, JSON.stringify(value));
    } catch (e) {
      console.error(e);
    }
  },
  isInternalNavigation(): boolean {
    return Object.prototype.hasOwnProperty.call(sessionStorage, USER_INTERNAL_NAVIGATION_KEY);
  },
  deleteInternalNavigation() {
    try {
      sessionStorage.removeItem(USER_INTERNAL_NAVIGATION_KEY);
    } catch (e) {
      console.error(e);
    }
  },
};
export const BannerVisibility = {
  doesExists(): boolean {
    return Object.prototype.hasOwnProperty.call(sessionStorage, VC_APP_DOWNLOAD_BANNER_KEY);
  },
  get(): boolean {
    try {
      if (this.doesExists()) {
        const value = JSON.parse(sessionStorage.getItem(VC_APP_DOWNLOAD_BANNER_KEY) || '');
        return value;
      } else {
        this.set(true);
        return true;
      }
    } catch (e) {
      console.error(e);
      return true;
    }
  },
  set(v: boolean) {
    try {
      sessionStorage.setItem(VC_APP_DOWNLOAD_BANNER_KEY, JSON.stringify(v));
    } catch (e) {
      console.error(e);
    }
  },
};

export function sanitiseArray(arr: Array<any>) {
  if (Array.isArray(arr) && arr.length > 0) {
    return arr.filter((a) => a);
  } else {
    return [];
  }
}

export function isValidEmail(input: string): boolean {
  const validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/;

  if (input.match(validRegex)) {
    return true;
  } else {
    return false;
  }
}
export function copyToClipboard(value: string) {
  navigator.clipboard.writeText(value);
}

export function getCheckoutPaymentEntries(walletAmountApplied: number, totalPayableAmount: number, paymentMethod: string):PaymentEntriesType[] {
  const result: PaymentEntriesType[] = [];
  if (walletAmountApplied > 0 && walletAmountApplied * 100 < totalPayableAmount) {
        //using both wallet and payment method
        result.push(
          { amount: walletAmountApplied * 100, paymentMethod: PaymentMethods.WALLET },
          { amount: totalPayableAmount - walletAmountApplied * 100, paymentMethod }
        );
      } else if (walletAmountApplied > 0 && walletAmountApplied * 100 == totalPayableAmount) {
        //using wallet completely.
        result.push({ amount: walletAmountApplied * 100, paymentMethod: PaymentMethods.WALLET });
      } else {
        //no wallet used
        result.push({ amount: totalPayableAmount, paymentMethod });
      }
  return result;
}

export function getDisplaySocialTag(socialTags: SIASocialTagSchema[]): {
  displayText: string,
  priority: SIASocialTagLevel,
  tags: string[]
} {

  const highPriorityTags = socialTags.filter(tag => tag.level === SIASocialTagLevel.HIGH);
  
  if(highPriorityTags.length) {
    const sortedTags = highPriorityTags.sort((a, b) => b.priority - a.priority).slice(0, 1).reverse();

    return {
      priority: SIASocialTagLevel.HIGH,
      displayText: sortedTags.map(tag => tag.displayText).join(" "),
      tags: sortedTags.map(tag => tag.socialTag)
    };
  }

  const lowPriorityTags = socialTags.filter(tag => tag.level === SIASocialTagLevel.LOW);

  if(lowPriorityTags.length) {
    const sortedTags = lowPriorityTags.sort((a, b) => b.priority - a.priority).slice(0, 2).reverse();

    return {
      priority: SIASocialTagLevel.LOW,
      displayText: sortedTags.map(tag => tag.displayText).join(" "),
      tags: sortedTags.map(tag => tag.socialTag)
    };
  }

  return {
    displayText: "",
    priority: SIASocialTagLevel.LOW,
    tags: []
  };
}