import { jsx as _jsx } from "@emotion/react/jsx-runtime";

/** @jsxImportSource @emotion/react */
import Lottie from 'react-lottie';
import AnimationJSON from './page-loader.json';
import { containerStyles } from './page-loader.module.style';
/* eslint-disable-next-line */

var defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: AnimationJSON,
  renderer: 'svg'
};
export function PageLoader(props) {
  return _jsx("div", {
    css: containerStyles,
    children: _jsx(Lottie, {
      options: defaultOptions,
      height: 150,
      width: 150
    })
  });
}
export default PageLoader;