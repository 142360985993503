import { useEffect } from 'react';
import Router from 'next/router';

function saveScrollPos(asPath) {
  sessionStorage.setItem("scrollPos:".concat(asPath), JSON.stringify({
    x: window.scrollX,
    y: window.scrollY
  }));
}

function restoreScrollPos(asPath) {
  var json = sessionStorage.getItem("scrollPos:".concat(asPath));
  var scrollPos = json ? JSON.parse(json) : undefined;

  if (scrollPos) {
    window.scrollTo(scrollPos.x, scrollPos.y);
  } else {
    window.scrollTo(0, 0);
  }
}

export function useScrollRestoration(router) {
  useEffect(function () {
    if (!('scrollRestoration' in window.history)) return;
    var shouldScrollRestore = false;
    window.history.scrollRestoration = 'manual';
    restoreScrollPos(router.asPath);

    var onBeforeUnload = function onBeforeUnload(event) {
      saveScrollPos(router.asPath);
      delete event['returnValue'];
    };

    var onRouteChangeStart = function onRouteChangeStart() {
      saveScrollPos(router.asPath);
    };

    var onRouteChangeComplete = function onRouteChangeComplete(url) {
      if (shouldScrollRestore) {
        shouldScrollRestore = false;
        /**
         * Calling with relative url, not expected asPath, so this
         * will break if there is a basePath or locale path prefix.
         */

        restoreScrollPos(url);
      }
    };

    window.addEventListener('beforeunload', onBeforeUnload);
    Router.events.on('routeChangeStart', onRouteChangeStart);
    Router.events.on('routeChangeComplete', onRouteChangeComplete);
    Router.beforePopState(function () {
      shouldScrollRestore = true;
      return true;
    });
    return function () {
      window.removeEventListener('beforeunload', onBeforeUnload);
      Router.events.off('routeChangeStart', onRouteChangeStart);
      Router.events.off('routeChangeComplete', onRouteChangeComplete);
      Router.beforePopState(function () {
        return true;
      });
    };
  }, [router]);
}