function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import { css } from '@emotion/react';
export var containerStyles = true ? {
  name: "1excoa1",
  styles: "position:fixed;top:0px;left:0px;width:100%;height:100%;display:flex;align-items:center"
} : {
  name: "1qrfwwn-containerStyles",
  styles: "position:fixed;top:0px;left:0px;width:100%;height:100%;display:flex;align-items:center;label:containerStyles;",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi91c3Ivc3JjL2FwcC9hcHBzL21hcmtldHBsYWNlL2NvbXBvbmVudHMvcGFnZS1sb2FkZXIvcGFnZS1sb2FkZXIubW9kdWxlLnN0eWxlLnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFHK0IiLCJmaWxlIjoiL3Vzci9zcmMvYXBwL2FwcHMvbWFya2V0cGxhY2UvY29tcG9uZW50cy9wYWdlLWxvYWRlci9wYWdlLWxvYWRlci5tb2R1bGUuc3R5bGUudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiXG5pbXBvcnQge2Nzc30gZnJvbSAnQGVtb3Rpb24vcmVhY3QnO1xuXG5leHBvcnQgY29uc3QgY29udGFpbmVyU3R5bGVzID0gY3NzKHtcbiAgcG9zaXRpb246ICdmaXhlZCcsXG4gIHRvcDogJzBweCcsXG4gIGxlZnQ6ICcwcHgnLFxuICB3aWR0aDogJzEwMCUnLFxuICBoZWlnaHQ6ICcxMDAlJyxcbiAgZGlzcGxheTogJ2ZsZXgnLFxuICBhbGlnbkl0ZW1zOiAnY2VudGVyJ1xufSkiXX0= */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};